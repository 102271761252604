
import { Box, Button, Card, CardContent, CardMedia, Container, Grid, Typography } from '@mui/material';
import React from 'react';

const Services = () => {
    const services = [
        {
          title: "Web Development",
          description: "Transform your online presence with our expert web development services.",
    
          image: "/images/web-development.png", // Image path
        },
        {
          title: "Mobile App Development",
          description: "Elevate your business with our cutting-edge mobile app development solutions.",
          image: "/images/mobile-app-development.png", // Image path
        },
        {
          title: "UI/UX Design",
          description: "Crafting intuitive and visually stunning user experiences to your audience.",
          image: "/images/ui-ux-design.png", // Image path
        },
        {
          title: "Quality Assurance",
          description: "Ensuring impeccable standards and functionality through rigorous testing.",
          image: "/images/quality-assurance.png", // Image path
        },
        {
          title: "Cloud Services",
          description: "Empowering businesses with scalable and reliable infrastructure solutions",
    
          image: "/images/cloud-services.jpg", // Image path
        },
        {
          title: "Software Maintenance",
          description: "Ensuring smooth operation and longevity of software systems through proactive.",
    
          image: "/images/software-maintenance.png", // Image path
        },
      ];
    
    return (
        <>
      

<Box id="services"
        sx={{
          minHeight: "100vh",
          width: "100%",
          display: "grid",
          placeItems: "center",
          bgcolor: "background.primary",
          textAlign: "center",
          color: "text.primary",
          borderRadius: 8,
          marginBottom:5

        }}
      >
        <Container style={{marginTop:"20px"}}  maxWidth="lg">
          {/* <Typography variant="h4" marginBottom={8}>Our Services</Typography> */}
          <div style={{textAlign:"left"}}>
          
          <h1 className="title">Our Services</h1>

        <p>
        Our IT agency offers cutting-edge software development and comprehensive <br/> digital marketing solutions to help businesses thrive in the digital age.
          </p>
          </div>
          <Grid  container marginBottom={3} spacing={3} justifyContent="center">
            {services.map((service, index) => (
              <Grid item xs={12} sm={6} md={4}  key={index}>
                <Card style={{borderRadius:"20px", padding:"20px"}}>
                  <CardMedia
                    component="img"
                    height="200"
                    image={service.image}
                    alt={service.title}
                  />
                  <CardContent>
                    <Typography variant="h5" gutterBottom>
                      {service.title}
                    </Typography>
                    <Typography variant="body1">{service.description}</Typography>
                    <Button variant="outlined" style={{ color: 'white', borderColor: 'white', marginTop:"30px"}}>
      See more
    </Button>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>

        </Container>

      </Box>
     
    </>
    );
};

export default Services;