import { Link } from 'react-router-dom';
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import logo from '../../../images/logo.png';
import './Navbar.css';
import {Navbar, Nav, Container} from 'react-bootstrap';

const Navbars = () => {

 
    

    return (
        <>
            <Navbar fixed="top" expand="lg" style={{marginBottom:"100px"}}>
                <Container>
                    <Navbar.Brand>
                        <Link to="/" className="navbar-brand">
                            <img src={logo} width={140} alt="" className="img-fluid"/>
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="" style={{marginLeft:"auto", marginTop:"10px"}}>
                            <Nav.Link href='/'>Home</Nav.Link>
                            <Nav.Link href="#portfolio">Our Portfolio</Nav.Link>
                            <Nav.Link href="#services">Services</Nav.Link>
                            <Nav.Link href="#team">Team</Nav.Link>

                            <Nav.Link href="#pricing">Pricing</Nav.Link>
 
                            <Nav.Link href="#blog">Blog</Nav.Link>
                            <Nav.Link className='btn btn-primary btn-brand' href="">Login</Nav.Link>
                            
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>            
        </>
    );
};

export default Navbars;