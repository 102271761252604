import React from 'react';
import Client from '../Client/Client';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import Navbars from '../Navbar/Navbars';
import OurWorks from '../OurWorks/OurWorks';
import Services from '../Services/Services';
import Blog from '../Blog/Blog';

import Team from '../Team/Team';

const HeaderMain = () => {
    return (
        <>
            <Navbars />
            <Header />
            <Client />
            <Services />
            <OurWorks />
            <Team/>
            {/* <Pricing/> */}
            <Blog/>
            <Footer />
        </>
    );
};

export default HeaderMain;