import { Facebook, LinkedIn, Pinterest, Twitter } from '@mui/icons-material';
import React from 'react';
import najib from "../../../images/NajibPassport1.jpg"
import mohsin from "../../../images/mohsin1.jpg"
import tanveer from "../../../images/tanveer.jpeg"
import mahbuba from "../../../images/mahbuba.jpeg"

const Team = () => {
    return (
        <div>
               <section class="section-white" id='team'>
 
 <div class="container">

     <div class="row">
    
                 {/* <div class="col-md-12 text-center">

                       <h2 class="section-title"></h2>

                       <p class="section-subtitle">There are many variations of passages of lorem ipsum available but the majority have suffered alternation in some injected humour,</p>
                       
                 </div>  */}

                 <div style={{textAlign:"left"}}>
          
          <h1 className="title">The Team Behind Pacifico</h1>

        <p>
          

            Our team is dedicated to delivering top-notch technology solutions tailored to your business needs.<br/>
             With expertise and innovation, we ensure your digital transformation is seamless and effective.
          </p>
          </div>
          
         <div class="col-sm-6 col-md-4">

               <div class="team-item">
               
                   <img src={tanveer} class="team-img" alt="pic" />                   
                   <h3>Abu Talab Tanveer</h3>            
                   <div class="team-info"><p>Head of IT Department</p></div>
                   
                   <ul class="team-icon">
                   
                   <li><a href="#" class="twitter"><Twitter/></a></li>
                   
                   <li><a href="#" class="linkedin">
                    <LinkedIn/>
                 </a></li>
                   <li><a href="#" class="pinterest"><Pinterest/></a></li>
                   
                   <li><a href="#" class="facebook"><Facebook/></a></li>
                   
                   
                 
                   
               </ul>
                   
               
             </div>
         </div> 
           
         <div class="col-sm-6 col-md-4">

               <div class="team-item">
               
                   <img src={mohsin} class="team-img" alt="pic" />
                  
                   <h3>Md. Mohsin Kabir</h3>
                   
                   <div class="team-info"><p>Fullstack Developer</p></div>

              
                   <ul class="team-icon">
                   
                       <li><a href="#" class="twitter"><Twitter/></a></li>
                       
                       <li><a href="#" class="linkedin">
                        <LinkedIn/>
                     </a></li>
                       <li><a href="#" class="pinterest"><Pinterest/></a></li>
                       
                       <li><a href="#" class="facebook"><Facebook/></a></li>
                       
                       
                     
                       
                   </ul>
                   
               </div>

         </div> 
         <div class="col-sm-6 col-md-4">

               <div class="team-item">
               
                   <img src={najib} class="team-img" alt="pic" />
                  
                   <h3>Najib Ahmed</h3>
                   
                   <div class="team-info"><p>Flutter Developer</p></div>

                  
                   <ul class="team-icon">
                   
                       <li><a href="#" class="twitter"><Twitter/></a></li>
                       
                       <li><a href="#" class="linkedin">
                        <LinkedIn/>
                     </a></li>
                       <li><a href="#" class="pinterest"><Pinterest/></a></li>
                       
                       <li><a href="#" class="facebook"><Facebook/></a></li>
                       
                       
                     
                       
                   </ul>
                   
               </div>

         </div> 
         <div class="col-sm-6 col-md-4">

               <div class="team-item">
               
                   <img src={mahbuba} class="team-img" alt="pic" />
                  
                   <h3>Mahbuba Haque Laka</h3>
                   
                   <div class="team-info"><p>Web Developer & SQA Engineer</p></div>

                   
                   <ul class="team-icon">
                   
                       <li><a href="#" class="twitter"><Twitter/></a></li>
                       
                       <li><a href="#" class="linkedin">
                        <LinkedIn/>
                     </a></li>
                       <li><a href="#" class="pinterest"><Pinterest/></a></li>
                       
                       <li><a href="#" class="facebook"><Facebook/></a></li>
                       
                       
                     
                       
                   </ul>
                   
               </div>

         </div> 
     
     </div> 
 
 </div> 

 </section>
        </div>
    );
};

export default Team;