import React, { createContext, useState } from 'react';
import './App.css';
import HeaderMain from './components/Home/HeaderMain/HeaderMain';
import Login from './components/Login/Login';
import Orders from './components/Dashboard/Orders/Orders';
import ServiceList from './components/Dashboard/serviceList/serviceList';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; 

export const UserContext = createContext();

function App() {
  const [loggedInUser, setLoggedInUser] = useState({});
  return (
    <UserContext.Provider value={[loggedInUser, setLoggedInUser]}>
      <Router>
        <Routes>
          <Route path="/" element={<HeaderMain />} />
          <Route path="/login" element={<Login />} />
          <Route path="/orders/:id" element={<Orders />} />
          <Route path="/serviceList" element={<ServiceList />} />
        </Routes>
      </Router>
    </UserContext.Provider>
  );
}

export default App;
