import { AppBar, Container, Toolbar, Typography } from '@mui/material';

const Footer = () => {
  return (
    <AppBar position="static" style={{backgroundColor:"#111430"}}>
      <Container maxWidth="lg">
        <Toolbar>
          <Typography variant="body1" color="inherit">
            © 2024 Your Company Name. All rights reserved.
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Footer;
